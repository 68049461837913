<app-cabecalho [cor]="cor" (mostrarEnvio)="onMostrarChange($event)"></app-cabecalho>
<app-menu-lateral (mostrar)="onMostrarChange($event)">
  <main class="c-app_conteudo">
    <!-- comentando notificacao -->
    <!-- <app-notificacao
      [mostrar]="this.mostrar"
      [data]="dataSource"
      class="notificacao"
      (notificacao)="notificacaoLida($event)"></app-notificacao> -->
    <div class="padding">
      <div class="c-app_titulo">
        <h1>{{ tituloPagina.titulo() }}</h1>
        <div class="alertSomenteView" *ngIf="tituloPagina.alertaSomenteVisualizacao() && !isHomeRoute">
          Somente visualização <img src="/assets/img/icons/somenteView.svg" />
        </div>
      </div>
      <div class="c-app_container">
        <div class="logo-container" *ngIf="isHomeRoute">
          <!-- <div class="minilogo-container">
            <img src="/assets/logo.svg" class="minilogo" />
          </div> -->
          <img src="/assets/petrobras.svg" class="logo" />
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>
</app-menu-lateral>