<mat-expansion-panel
  #turnoExpansionPanel
  class="c-collapse-menu-lateral_container">
  <mat-expansion-panel-header
    *ngIf="permitir"
    matRipple
    class="c-collapse-menu-lateral_cabecalho"
    [ngClass]="{ 'is-aberto': expansionPanel?.expandedChange | async }">
    <mat-panel-title class="c-collapse-menu-lateral_titulo"
      >Fluxo de carga</mat-panel-title
    >
  </mat-expansion-panel-header>
  <div class="c-collapse-menu-lateral_conteudo">
    <a
      matRipple
      class="c-collapse-menu-lateral_link"
      [routerLink]="['/fluxoCarga/cadastroViagem']"
      *ngIf="buscarPermissao('CadastroEntradaSaida') != 0"
      routerLinkActive="is-ativo"
      (isActiveChange)="rolarParaInicio()"
      (click)="fecharMenu()"
      >Cadastro de entrada</a
    >
    <a
      matRipple
      class="c-collapse-menu-lateral_link"
      [routerLink]="['/fluxoCarga/listaViagem']"
      *ngIf="buscarPermissao('Viagens') != 0"
      routerLinkActive="is-ativo"
      (isActiveChange)="rolarParaInicio()"
      (click)="fecharMenu()"
      >Viagens</a
    >
  </div>
</mat-expansion-panel>
