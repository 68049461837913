import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TituloPaginaService {
  titulo = signal('');
  alertaSomenteVisualizacao = signal(false);

  defineTitulo(titulo: string): void {
    this.titulo.set(titulo);
  }

  defineAlertaSomenteVisualizacao(alerta: boolean): void {
    this.alertaSomenteVisualizacao.set(alerta);
  }
}
